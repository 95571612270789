.loader {
    display: block;
    --height-of-loader: 4px;
    --loader-color: #0071e2;
    width: 130px;
    height: var(--height-of-loader);
    border-radius: 30px;
    background-color: rgba(0,0,0,0.2);
    position: relative;
  }
  
  .loader::before {
    content: "";
    position: absolute;
    background: var(--loader-color);
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    border-radius: 30px;
    animation: moving 1s ease-in-out infinite;
    ;
  }
  
  @keyframes moving {
    50% {
      width: 100%;
    }
  
    100% {
      width: 0;
      right: 0;
      left: unset;
    }
  }

  // Full loader
  
.fullLoader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff; // Change this color to match your design needs
  z-index: 9999; // Ensures loader is on top of other elements

  .loader-image {
    width: 300px; // Adjust the size as needed
    height: auto;
  }
}