@import './Views/styles/breakpoints.scss';
@import './Views/styles/mixins.scss';

// DEBUG CSS
// * {
//   outline: 1px solid red;
// }

:root {
  --title-f-size: 3.313rem;
  --subTitle-f-size: 2rem;
  --input-f-size: 1rem;
  --padding-btn-action: 10px 60px 10px 60px;
  --extraPadding-btn-action: 10px 40px 10px 40px;
  --letter-spacing: 0.01rem;
  --btn-height: 2.75rem;
  --color-green: #20b19d;
  --color-title: #374a49;
  --color-red: #ff4645;
  --color-black: #495057;
}

a {
  color: inherit !important;
}

a:hover {
  color: inherit !important;
  background: inherit !important;
  // padding: 0px !important;
  text-decoration: none !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@font-face {
  font-family: 'Sharp';
  src: local('Sharp'), url(./fonts/Sharp_Grotesk/SharpGrotesk-Black10.otf) format('truetype');
}

@font-face {
  font-family: 'SharpBold';
  src: local('SharpBold'), url(./fonts/Sharp_Grotesk/SharpGrotesk-Book20.otf) format('truetype');
}

@font-face {
  font-family: 'SharpGrotesk';
  src: local('SharpBold'), url(./fonts/Sharp_Grotesk/SharpGrotesk-Light20.otf) format('truetype');
}

@font-face {
  font-family: 'SharpThin';
  src: local('SharpThin'), url(./fonts/Sharp_Grotesk/SharpGrotesk-Thin20.otf) format('truetype');
}

@font-face {
  font-family: 'SharpMedium20';
  src: local('SharpMedium20'), url(./fonts/Sharp_Grotesk/SharpGrotesk-Medium20.otf) format('truetype');
}

@font-face {
  font-family: 'SharpSemiBold20';
  src: local('SharpSemiBold20'), url(./fonts/Sharp_Grotesk/SharpGrotesk-SemiBold20.otf) format('truetype');
}

@font-face {
  font-family: 'Nova';
  src: local('Nova'), url(./fonts/ProximaNova-Regular.otf) format('truetype');
}

@font-face {
  font-family: 'NovaBlack';
  src: local('NovaBlack'), url(./fonts/ProximaNova-Black.otf) format('truetype');
}

.poss-relative {
  position: relative;
}

.align-center {
  text-align: center;
}

.align-left {
  text-align: left;
}

.no-padding {
  padding: 0px !important;
}

.no-padding-lateral {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.no-padding-left {
  padding-left: 0px !important;
}

.no-padding-right {
  padding-right: 0px !important;
}

.homeProperty img {
  height: 100%;
}

.float-right {
  float: right;
}

.list-none {
  list-style: none;
}

.absolute {
  position: absolute;
}

/*forzando bootstrap widht*/
.container-lg {
  max-width: 100vw !important;
  padding-top: 20px;

  @include respond-to(medium) {
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding: 0px !important;
    // background-color: red;
  }
}

.f-black {
  font-family: NovaBlack !important;
}

.b-radius-left-top-50 {
  border-top-left-radius: 50px;
}

.b-radius-left-bottom-50 {
  border-bottom-left-radius: 50px;
}

.b-radius-right-top-50 {
  border-top-right-radius: 50px;
}

.b-radius-right-bottom-50 {
  border-bottom-right-radius: 50px;
}

.b-radius-left-top-15 {
  border-top-left-radius: 15px;
}

.b-radius-left-bottom-15 {
  border-bottom-left-radius: 15px;
}

.b-radius-right-top-15 {
  border-top-right-radius: 15px;
}

.b-radius-right-bottom-15 {
  border-bottom-right-radius: 15px;
}

.b-left-none {
  border-left: none;
}

.b-right-none {
  border-right: none;
}

.b-radius {
  border-radius: 3rem;
}

a.nav-link.button.outline-green,
.outline-green {
  background-image: none !important;
  color: var(--color-green) !important;
  border: 1px solid var(--color-green) !important;
}

a.nav-link.button.outline-green:hover,
.outline-green:hover {
  background-image: linear-gradient(to right, #57b4a4, #20b19d) !important;
  color: white !important;
}

.btn-full-screen {
  font-size: 12px !important;
  padding: 15px 15px !important;
  height: auto !important;
  position: absolute !important;
  right: 25px !important;
  bottom: 50px !important;
}

@media (max-width: 768px) {
  .btn-full-screen {
    font-size: 12px !important;
    padding: 15px 15px !important;
    height: auto !important;
    position: absolute !important;
    right: 25px !important;
    bottom: 35px !important;
  }
}

.btn-outline {
  background: white !important;
  color: var(--color-green) !important;
  border: 1px solid var(--color-green) !important;
}

.btn-outline:hover {
  background: var(--color-green) !important;
  color: white !important;
}

.vert-align {
  display: flex;
  align-items: center;
}

.map-content-property {
  width: 250px !important;
  padding: 0px !important;
  overflow: hidden;

  @include respond-to('small') {
    width: 100% !important;
  }

}

.map-content-property .title-name-home {
  @include respond-to('small') {
    font-size: 11px !important;
    line-height: 10px !important;
    font-family: 'Nova';
  }
}

.map-content-property .type-text-home {
  @include respond-to('small') {
    font-size: 10px !important;
  }
}


.map-content-property .from-precio-prop {
  @include respond-to('small') {
    font-size: 10px;
  }
}

img[src*='https://maps.gstatic.com/mapfiles/transparent.png'] {
  @include respond-to('small') {
    width: 50px !important;
    height: 58px !important;
  }
}

.map-content-property .currency-precio-prop {
  @include respond-to('small') {
    font-size: 14px;
  }
}

.gm-style .gm-style-iw-d {
  @include respond-to('small') {
    overflow: initial !important;
  }
}

.map-content-property .button-price-viajero {
  @include respond-to('small') {
    border-radius: 14px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.separator {
  border: 1px solid lightgray;
  margin: 30px 0px 0px 0px;
}

.btn-danger-action {
  color: red !important;
  border-color: red !important;
  margin-left: 15px;
}

.btn-danger-action:hover {
  background-color: red !important;
  color: white !important;
  background-image: none !important;
}

.form-control {
  height: calc(1.5em + .75rem + 14px) !important;
}

.btn-red,
.btn-red:hover {
  color: white !important;
  background: var(--color-red) !important;
  border-color: var(--color-red) !important;
}

.fs-xs {
  font-size: smaller !important;
}

.uppercase {
  text-transform: uppercase;
}

.btn-full {
  background: var(--color-green) !important;
  color: white !important;
}

.container_map {
  position: relative;
  width: 45%;
  // @media only screen and (max-width: 2796px) and (max-height: 1290px)  {
  @include respond-to(medium) {
    height: 500px;
    width: 100%;
    margin-bottom: 30px;
    // margin-top: 10px;
  }
}

.shadow-sprite {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  z-index: 4;
  width: 45%;
  height: 100vh;
  position: absolute;
  right: 0;
}



.rootContainer {
  @include respond-to(medium) {
    overflow-x: hidden;
  }
}


//Layout things

.layout-container{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.layout-navbar{
  height: 80px;
}
.layout-component{
  flex: 3;
  min-height: 850px;
}


.navbar-background {
  background-color: black;
  height: 80px;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
}

//Slider things
.welcome-carousel-container{
  
}
.slick-slide img{
  width: 100%;
}
.slick-next{
  right: 25px!important;
  opacity: 0;
  padding-top: 80px !important;
  padding-bottom: 80px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.slick-slider:hover .slick-next{
  opacity: 1;
}
.slick-prev{
  left: 25px!important;
  z-index: 999;
  opacity: 0;
  padding-top: 80px !important;
  padding-bottom: 80px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.slick-slider:hover .slick-prev{
  opacity: 1;
}

//Propertieslist
.filter-container-box-list {
  padding: 0px 10px;
  // height: 100%;
}


.text-decoration-underline{
  text-decoration: underline;
}

.hover-pointer{
  cursor: pointer;
}